import React, { useState, useEffect, useCallback } from 'react';
import S from '../../styled';
import { getTheme } from '../../styled/Themes';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';

// Model Requests
import { getCampaign, getPerformanceRates, getInformations } from '../../data/requests/activations';
import { getTasksByCampaignAndToken } from '../../data/requests/task';

// Components
import Page from '../../components/Page';
import Module from '../../components/Module';
import Task from '../../components/TasksModule/Task';

// Icons
import { ThumbsUp as ThumbsUpIcon } from '@styled-icons/fa-regular';
import { ChevronLeft } from '@styled-icons/fa-solid';

export default function CampaignSummary(props) {
  const history = useHistory();

  const { campaignId } = props.match.params;
  const { store, setStore, profile } = props;
  const { token, t } = store;
  const theme = getTheme(props.store.theme);

  const [campaign, setCampaign] = useState([]);
  const [information, setInformation] = useState([]);
  const [performanceRate, setPerformanceRate] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTasks, setIsLoadingTasks] = useState(true);
  const [completeTasks, setCompleteTasks] = useState([]);
  const [incompleteTasks, setIncompleteTasks] = useState([]);

  const getCurrencySymbol = () => {
    if (performanceRate) return getSymbolFromCurrency(performanceRate.rate_currency || 'USD')
  };

  const getCampaignData = useCallback(async () => {
    if (!token) return;

    setIsLoading(true);
    try {
      const campaignData = await getCampaign(token, campaignId);
      let informationData = await getInformations(token, campaignId);
      const performanceRateData = await getPerformanceRates(token, campaignId);
      if (informationData) delete informationData.id;
      informationData = informationData && Object.fromEntries(Object.entries(informationData).filter(([_, v]) => v != null && v !== ""));
      setCampaign(campaignData);
      setInformation(informationData);
      setPerformanceRate(performanceRateData);
    } catch (error) {
      console.error('getCampaignData: ', error);
    } finally {
      if (isMounted) setIsLoading(false);
    }
  }, [campaignId, isMounted, token]);

  const getTaskData = useCallback(async () => {
    if (!token) return;

    setIsLoadingTasks(true);
    try {
      const incompleteData = await getTasksByCampaignAndToken(token, campaignId);
      const completeData = await getTasksByCampaignAndToken(token, campaignId, true);
      setIncompleteTasks(incompleteData);
      setCompleteTasks(completeData);
    } catch (error) {
      console.error('getTaskData: ', error);
    } finally {
      if (isMounted) setIsLoadingTasks(false);
    }
  }, [campaignId, isMounted, token]);

  useEffect(() => {
    getCampaignData();
    getTaskData();
    return () => setIsMounted(false);
  }, [getCampaignData, getTaskData]);

  const start = moment(campaign.start_date).format('MMM Do YYYY');
  const end = moment(campaign.end_date).format('MMM Do YYYY');

  const keywords = () => {
    if (campaign.keywords) return campaign.keywords.join(', ');
  };

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const emptyPendingTasksContent = () => {
    return (
      <S.Flex column>
        <S.Flex row paddingBottom={10}>
          <S.SvgWrapper height={50} width={50}>
            <ThumbsUpIcon style={{ color: theme.button.color.default }} />
          </S.SvgWrapper>
        </S.Flex>
        <S.Flex row textAlignCenter padding={5}>
          <S.Text boldWeight smallSize>
            {t('pages.CampaignSummary.noPendingTasks')}
          </S.Text>
        </S.Flex>
        <S.Flex row textAlignCenter padding={5}>
          <S.Text smallSize lightWeight>
            {t('pages.CampaignSummary.tasksWillAppearHere')}
          </S.Text>
        </S.Flex>
      </S.Flex>
    );
  };

  const titleContent = (
    <S.Flex flex={1} justifyStart>
      <S.Link marginTop={0} onClick={goBack}><ChevronLeft size={25} /></S.Link>
      <S.Text largeSize boldWeight capitalize whitespaceNoWrap paddingRight={40}>
        {t('pages.CampaignSummary.campaignOverview')}
      </S.Text>
    </S.Flex>
  );

  return (
    <Page
      title={campaign.name}
      isLoading={isLoading}
    >
      <Module
        isLoading={isLoading}
        title={titleContent}
      >
        <S.Flex column justifyStart overflowHidden marginTop={15}>
          <S.Flex justifyStart flex={1} marginBottom={20}>
            <S.Text>{t('pages.CampaignSummary.campaignDates')}: <S.Text lightWeight as="span">{start} - {end}</S.Text></S.Text>
          </S.Flex>
          {keywords() && (
            <S.Flex justifyStart flex={1}  marginBottom={20}>
              <S.Text>{t('pages.CampaignSummary.postTagging')}: <S.Text lightWeight as="span">{keywords()}</S.Text></S.Text>
            </S.Flex>
          )}
          {performanceRate && (
            <>
              <S.Flex justifyStart flex={1}>
                <S.Text>{t('pages.CampaignSummary.compensation')}:</S.Text>
              </S.Flex>
              {performanceRate.base_rate &&
                <S.Flex justifyStart flex={1}>
                  <S.Text lightWeight as="li">{t('pages.CampaignSummary.baseRate')}: {getCurrencySymbol()}{performanceRate.base_rate} {performanceRate.rate_currency}</S.Text>
                </S.Flex>
              }
              {performanceRate.conversion_target_to_rates && Object.keys(performanceRate.conversion_target_to_rates).map((conversions, amount) => (
                <S.Flex justifyStart flex={1}  key={`performance-rate-${amount}`}>
                  <S.Text lightWeight as="li">{t('navigation.bonus')} {amount+1}: {conversions} {t('navigation.conversions')} | {getCurrencySymbol()}{performanceRate.conversion_target_to_rates[conversions]} {performanceRate.rate_currency}</S.Text>
                </S.Flex>
              ))}
            </>
          )}
          {information && Object.keys(information).map((info, index) => (
            <S.Flex  marginTop={20} justifyStart column flex={1} key={`information-${index}`}>
              <S.Flex justifyStart row>
                <S.Text>{t('pages.CampaignSummary.campaignInfo')} {index+1}</S.Text>
              </S.Flex>
              <S.Flex justifyStart row>
                <S.Text lightWeight>{information[info]}</S.Text>
              </S.Flex>
            </S.Flex>
          ))}
        </S.Flex>
      </Module>
      <Module
        title={t('pages.CampaignSummary.myTasks')}
        isLoading={isLoadingTasks}
      >
        <S.Flex justifyStart row marginTop={20} marginBottom={15}>{t('pages.CampaignSummary.pendingTasks')}</S.Flex>
        {incompleteTasks.length ? (
          incompleteTasks.map((task, i) => (
            <S.Flex flex={0} key={`${task.id}-${i}`}>
            <Task
              token={token}
              task={task}
              profile={profile}
              setStore={setStore}
              onTaskUpdated={getTaskData}
              summaryView={true}
              store={store}
            />
          </S.Flex>
          ))
        ) : (
          emptyPendingTasksContent()
        )}
        <S.Flex justifyStart row marginTop={20} marginBottom={15}>{t('pages.CampaignSummary.completedTasks')}</S.Flex>
        {completeTasks.length ? (
          completeTasks.map((task, i) => (
          <S.Flex flex={0} key={`${task.id}-${i}`}>
            <Task
              token={token}
              task={task}
              profile={profile}
              setStore={setStore}
              onTaskUpdated={getTaskData}
              summaryView={true}
              store={store}
            />
          </S.Flex>
          ))
        ) : (
          <S.Flex lightWeight overflowHidden>{t('pages.CampaignSummary.noCompletedTasksToDisplay')}</S.Flex>
        )}
      </Module>
    </Page>
  );
};
