import React, { useState, useEffect, useCallback } from 'react';
import S from '../../styled';
import { useHistory } from 'react-router-dom';

// Components
import Page from '../../components/Page';
import CampaignProgramModule from '../../components/CampaignProgramsModule';
import MyCampaignsModule from '../../components/Campaigns/MyCampaigns';

export default function Campaigns(props) {
  const history = useHistory();

  const { store } = props;
  const { account, t } = store;

  const [isMounted, setIsMounted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const checkFeatureFlag = useCallback(async () => {
    if (!account) return;

    try {
      setIsLoading(true);
      if (account?.features?.indexOf('portal_campaign_summary') && !account?.features?.indexOf('portal_campaign_summary')) {
        history.goBack();
      }
    } finally {
      if (isMounted) setIsLoading(false);
    }
  }, [account, history, isMounted]);

  useEffect(() => {
    checkFeatureFlag();
    return () => setIsMounted(false);
  }, [checkFeatureFlag]);

  const availableCampaignsTitle = (
    <S.Flex row alignEnd justifyEnd flex={1}>
      {t('pages.Campaigns.availableCampaigns')}
    </S.Flex>
  );

  return (
    <Page
      title={t('navigation.campaigns')}
      isLoading={isLoading}
      alignStart
      justifyStart
    >
      <MyCampaignsModule
        store={store}
        completed={false}
      />
      <CampaignProgramModule
        store={store}
        available={true}
        title={availableCampaignsTitle}
        dashboardView={true}
      />
      <MyCampaignsModule
        store={store}
        completed={true}
      />
    </Page>
  );
};
