import { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

// Routing
import {
  shouldNavigateToNotFoundPage,
  shouldNavigateToInvalidPage,
  shouldNavigateToProfileReviewPage,
  shouldNavigateToLoginPage,
  shouldNavigateToRequestedPage,
  shouldNavigateToDelegateRelationshipSelectionPage,
} from './redirect';

// Helpers
import getUrlToken from '../helpers/getUrlToken';

const PrivateRoute = (props) => {
  const history = useHistory();
  const {
    layout: RouteLayout,
    page: RoutePage,
    exact,
    strict,
    location,
    ...otherProps
  } = props;

  const { authedStatus, currentUser, token, userIsLoggingOut } = props.store;
  const { setLastPrivateRouteVisited } = props.setStore;

  useEffect(() => {
    const shouldNavigateParams = {
      history,
      portal_token: token || getUrlToken(),
      currentUser,
      authedStatus,
      pathname: location.pathname,
      params: location.search,
      userIsLoggingOut,
      isPrivate: true,
    };

    shouldNavigateToNotFoundPage(shouldNavigateParams);
    shouldNavigateToInvalidPage(shouldNavigateParams);
    shouldNavigateToProfileReviewPage(shouldNavigateParams);
    shouldNavigateToLoginPage(shouldNavigateParams);
    shouldNavigateToRequestedPage(shouldNavigateParams);
    shouldNavigateToDelegateRelationshipSelectionPage(shouldNavigateParams);
  }, [authedStatus, currentUser, history, token, userIsLoggingOut, location.pathname, location.search]);

  useEffect(() => {
    if (otherProps.path) setLastPrivateRouteVisited(otherProps.path);
  }, [otherProps.path, setLastPrivateRouteVisited]);

  return (
    <Route
      strict={strict}
      exact={exact}
      path={otherProps.path}
      render={(routeProps) => (
        <RouteLayout {...otherProps} {...routeProps}>
          <RoutePage {...otherProps} {...routeProps} />
        </RouteLayout>
      )}
    />
  );
};

export default PrivateRoute;
