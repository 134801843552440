import React, { useState, useEffect, useCallback } from 'react';

// Styles
import S from '../../styled';
import { PhotoVideo } from '@styled-icons/fa-solid';
import { File } from '@styled-icons/fa-solid';
import { ChevronRight } from '@styled-icons/fa-solid';
import {
  NETWORKS,
  CONTENT_REVIEW_STATUS_APPROVED,
  POST_CONTENT_TASK
} from '../../helpers/constants';

export default function TaskCard({ index, task, review, processTaskClick, store }) {
  const { t } = store;

  const CONTENT_POST_AWAITING_CONTENT_DISPLAYABLE = t("components.TaskCard.awaitingContent");
  const CONTENT_POST_READY_TO_POST_DISPLAYABLE = t("components.TaskCard.readyToPost");
  const CONTENT_POST_COMPLETE_DISPLAYABLE = t("components.TaskCard.complete");

  const [reviewRequired] = useState(task?.review_required);
  const [taskStatus, setTaskStatus] = useState(task?.review_required ? CONTENT_POST_AWAITING_CONTENT_DISPLAYABLE : CONTENT_POST_READY_TO_POST_DISPLAYABLE);

  const setStatus = useCallback(() => {
    if (!task) return;

    let defaultStatus = t("components.TaskCard.defaultStatus");
    let statusToSet = defaultStatus;

    const taggedPostsCount = task.tagged_posts_count;

    if (reviewRequired) {
      const currentStatus = review?.status;
      const approvedNumber = review?.approvedNumber;

      const isApproved = currentStatus === CONTENT_REVIEW_STATUS_APPROVED;
      const isCompleted = typeof approvedNumber === 'number' && approvedNumber < taggedPostsCount;

      if (isApproved && isCompleted) {
        statusToSet = CONTENT_POST_COMPLETE_DISPLAYABLE;
      } else if (review) {
        statusToSet = defaultStatus;
      } else {
        statusToSet = CONTENT_POST_AWAITING_CONTENT_DISPLAYABLE;
      }
    } else {
      if (index < taggedPostsCount) {
        statusToSet = CONTENT_POST_COMPLETE_DISPLAYABLE;
      } else {
        statusToSet = CONTENT_POST_READY_TO_POST_DISPLAYABLE;
      }
    }

    setTaskStatus(statusToSet);
  }, [task, review, index, reviewRequired,
      CONTENT_POST_AWAITING_CONTENT_DISPLAYABLE, CONTENT_POST_COMPLETE_DISPLAYABLE,
      CONTENT_POST_READY_TO_POST_DISPLAYABLE, t]);

  useEffect(() => {
    setStatus();
  }, [setStatus]);

  const isPostContentTask = task.type === POST_CONTENT_TASK;

  const renderIcon = () =>
    isPostContentTask ? <PhotoVideo size={25} /> : <File size={25} />;

  const renderCardHeader = () =>
    isPostContentTask ? (
      <S.Text paragraph>
        {NETWORKS[task.network]} {t("components.TaskCard.post")} #{index + 1}
      </S.Text>
    ) : (
      <S.Text paragraph>
        {t("components.TaskCard.file")} #{index + 1}
      </S.Text>
    );

  return (
    <S.Flex
      flex={1}
      row
      alignCenter
      justifyBetween
      paddingBottom={10}
      clickable
      hoverable
      onClick={() => reviewRequired ? processTaskClick(review || {}) : {}}
    >
      <S.SvgWrapper border="1px solid" sharpBorderRadius margin={5} padding={5}>
        {renderIcon()}
      </S.SvgWrapper>
      <S.Flex column flex={1} justifyStart alignStart paddingLeft={20}>
        {renderCardHeader()}
        <S.Text muted lightWeight paragraph>
          {taskStatus}
        </S.Text>
      </S.Flex>
      <S.Flex height='100%'>
        <S.Flex flex={1} column alignEnd>
          {reviewRequired && <ChevronRight size={15} />}
        </S.Flex>
      </S.Flex>
    </S.Flex>
  );
}
