import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import S from '../../styled';

// Routes
import { routeNames } from '../../routing/routes';

// Components
import Page from '../../components/Page';
import HtmlContent from '../../components/HtmlContent';
import Module from '../../components/Module';

// Icons
import {
  CheckCircle as CheckIcon,
} from '@styled-icons/boxicons-regular';
import NetworkIcon from '../../components/NetworkIcon';

// Requests
import { getMagicLink } from '../../data/requests/user';

// Helpers
import {
  AUTHED_STATUS_INVALID,
  AUTHENTICATION_FACEBOOK,
  AUTHENTICATION_GOOGLE,
  EMAIL_VALIDATION_REGEX
} from '../../helpers/constants';
import navigateTo from '../../helpers/navigateTo';
import useQuery from '../../helpers/useQuery';
import { useAuthentication } from '../../authentication';


export default function Login(props) {
  const { history } = props;
  const { token, account, t, authenticatingNetwork, userIsLoggingOut } = props.store;
  const { setAuthedStatus, setUserIsLoggingOut } = props.setStore;
  const { delegates_enabled: isDelegatesEnabled } = account;

  const { loginToNetwork } = useAuthentication();
  const { email: emailQueryParam, delegate: delegateQueryParam } = useQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [renderSentEmail, setRenderSentEmail] = useState(false);
  const [isDelegate, setIsDelegate] = useState(false);

  useEffect(() => {
    if (userIsLoggingOut) setUserIsLoggingOut(false);
  }, [userIsLoggingOut, setUserIsLoggingOut]);

  useEffect(() => {
    if (emailQueryParam && !email) {
      setValidEmail(EMAIL_VALIDATION_REGEX.test(emailQueryParam));
      setEmail(emailQueryParam);
    }
  }, [emailQueryParam, email]);

  useEffect(() => {
    if (delegateQueryParam && !isDelegate) {
      setIsDelegate(Boolean(delegateQueryParam));
    }
  }, [delegateQueryParam, isDelegate]);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      await getMagicLink(token, email, isDelegate);
    } catch (error) {
      setAuthedStatus(AUTHED_STATUS_INVALID);
      navigateTo(history, routeNames.invalid, token);
    } finally {
      setIsLoading(false);
      setRenderSentEmail(true);
    }
  };

  const onEmailChange = (e) => {
    setValidEmail(EMAIL_VALIDATION_REGEX.test(e.target.value));
    setEmail(e.target.value);
  }

  const resetRenderSentEmail = (e) => {
    e.preventDefault();
    setRenderSentEmail(false);
  }

  const handleFacebookLogin = () => loginToNetwork(AUTHENTICATION_FACEBOOK);
  const handleGoogleLogin = () => loginToNetwork(AUTHENTICATION_GOOGLE);

  const signInPageText = account.portal_sign_in_page_text || `<p>${t('pages.Login.welcomeToCreatorPortal')} ${account.name || t('pages.Login.creator')} portal.</p>`;

  const accountLogoOrName = () => {
    const logoUrl = account.portal_logo_url || account.logo_url;
    const accountName = account.portal_brand_name || account.name;

    if (logoUrl) {
      return (
        <S.NavLogo
          layout={false}
          src={logoUrl}
          alt={`${accountName} logo`}
        />
      );
    } else if (accountName) {
      return <S.Text as="h1">{accountName}</S.Text>;
    } else {
      return null;
    }
  }

  const emailContent = () => {
    return (
      <S.Flex flex={1} column alignCenter justifyCenter maxWidth>
        {renderGoogleAppTracking()}
        <S.Flex>
          {accountLogoOrName()}
        </S.Flex>
        <S.Flex column gap={50} marginBottom={20} marginTop={26}>
          <S.Text headerSize>{t('pages.Login.checkYourEmail')}</S.Text>
        </S.Flex>
        <S.SvgWrapper height={100} width={100} buttonColor>
          <CheckIcon />
        </S.SvgWrapper>
        <S.Flex column gap={50} marginTop={20} marginBottom={26}>
          <S.Text>{t('pages.Login.magicLinkSent')}</S.Text>
          <S.Text lightWeight>
            {t('pages.Login.didNotReceiveEmail')}
            <S.Link href="#" network onClick={resetRenderSentEmail}>
              <S.Text as="span" lightWeight>{t('pages.Login.tryAgain')}</S.Text>
            </S.Link>
          </S.Text>
        </S.Flex>
      </S.Flex>
    );
  }

  const loginUserSelection = () => (
    <S.Flex>
      <S.Link navigation onClick={() => setIsDelegate(false)}>
        <S.Text
          boldWeight={!isDelegate}
          lightWeight={isDelegate}
          underline={!isDelegate}
          largeSize
          textUnderlineOffset={14}
          textDecorationThickness={3}
          lineHeight="2em"
        >
          {t('pages.Login.creatorLogin')}
        </S.Text>
      </S.Link>
      <S.Link navigation onClick={() => setIsDelegate(true)}>
        <S.Text
          boldWeight={isDelegate}
          lightWeight={!isDelegate}
          underline={isDelegate}
          largeSize
          textUnderlineOffset={14}
          textDecorationThickness={3}
          lineHeight="2em"
        >
          {t('pages.Login.guestLogin')}
        </S.Text>
      </S.Link>
    </S.Flex>
  );

  const loginContent = () => (
    <>
      {renderGoogleAppTracking()}
      <S.Flex>{accountLogoOrName()}</S.Flex>
      <HtmlContent
        name="htmlContent"
        textAlign="center"
        content={signInPageText}
        flex={1}
      />
      <S.Flex column gap={50} marginBottom={26} marginTop={46}>
        {isDelegatesEnabled && loginUserSelection()}
        {!isDelegate && (
          <>
            <S.Button
              network="facebook"
              disabled={!!authenticatingNetwork}
              onClick={handleFacebookLogin}
              gap={8}
            >
              <NetworkIcon
                network="facebook"
                backgroundColor="transparent"
                transparent={true}
              />
              {authenticatingNetwork !== AUTHENTICATION_FACEBOOK && (
                <div>{t('shared.continueWith')} Facebook</div>
              )}
              {authenticatingNetwork === AUTHENTICATION_FACEBOOK && (
                <S.Spinner />
              )}
            </S.Button>
            <S.Button
              network="google"
              disabled={!!authenticatingNetwork}
              onClick={handleGoogleLogin}
              gap={8}
            >
              <NetworkIcon network="google" />
              {authenticatingNetwork !== AUTHENTICATION_GOOGLE && (
                <div>{t('shared.continueWith')} Google</div>
              )}
              {authenticatingNetwork === AUTHENTICATION_GOOGLE && <S.Spinner />}
            </S.Button>
            <S.Flex flex={1}>
              <S.Flex flex={1}>
                <S.Divider flex={1} />
                <S.Text
                  width="unset"
                  justifyContent="center"
                  textAlignCenter
                  flex={1}
                  paddingLeft={40}
                  paddingRight={40}
                >
                  {t('shared.or')}
                </S.Text>
                <S.Divider flex={1} />
              </S.Flex>
            </S.Flex>
          </>
        )}
        <S.Text>{t('pages.Login.enterYourEmail')}</S.Text>
      </S.Flex>
      <S.Form column gap={26} onSubmit={onSubmit}>
        <S.Input
          name="email"
          placeholder={t('shared.emailAddress')}
          type="email"
          value={email}
          onChange={onEmailChange}
        />
        <S.Button type="submit" disabled={isLoading || !validEmail}>
          {t('pages.Login.sendMagicLink')}
        </S.Button>
      </S.Form>
    </>
  );

  const renderGoogleAppTracking = () => (
    <Helmet>
      <script
        type="text/javascript"
        src="https://secure.perk0mean.com/js/177560.js"
      ></script>
      <noscript>
        {`<img src="https://secure.perk0mean.com/177560.png" alt="" style="display:none;" />`}
      </noscript>
    </Helmet>
  );

  const contentToRender = () => renderSentEmail ? emailContent() : loginContent();

  return (
    <Page clear alignCenter justifyCenter>
      <S.Visibility tablet desktop alignCenter justifyCenter maxWidth>
        <Module outlined flex={1}>
          <S.Flex
            column
            alignCenter
            justifyCenter
            flex={1}
            padding={120}
            paddingTop={20}
            paddingBottom={60}
          >
            {contentToRender()}
          </S.Flex>
        </Module>
      </S.Visibility>
      <S.Visibility mobile alignCenter justifyCenter maxWidth>
          <S.Flex
            column
            alignCenter
            justifyCenter
            flex={1}
            padding={20}
            paddingBottom={60}
          >
            {contentToRender()}
          </S.Flex>
      </S.Visibility>
    </Page>
  );
}
