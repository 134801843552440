import React, { useState, useEffect, useCallback } from 'react';
import S from '../../styled';
import { useHistory } from 'react-router-dom';

// Routes
import { paths } from '../../routing/routes';

// Model Requests
import { getPromotionsByToken } from '../../data/requests/promotions';

// Components
import Module from '../Module';
import NameDatesRow from '../Reusable/NameDatesRow';
import HtmlContent from '../HtmlContent';

// Helpers
import navigateTo from '../../helpers/navigateTo';

export default function PromotionsModule(props) {
  const history = useHistory();

  const { store: { token, t }, setStore } = props;

  const [isMounted, setIsMounted] = useState(true);
  const [promotions, setPromotions] = useState([]);
  const [moduleIsLoading, setModuleIsLoading] = useState(true);

  const loadModuleData = useCallback(async () => {
    if (!token) return;

    try {
      setModuleIsLoading(true);
      const promotions = await getPromotionsByToken(token);

      setPromotions(promotions || []);
    } catch (error) {
      console.error('loadModuleData: ', error);
    } finally {
      if (isMounted) setModuleIsLoading(false);
    }
  }, [token, isMounted]);

  useEffect(() => {
    loadModuleData();
    return () => setIsMounted(false);
  }, [loadModuleData]);

  const navigateToPromotion = (promotion) => {
    navigateTo(
      history,
      paths.promotion,
      token,
      {
        queryParams: {
          promotionId: promotion.id
        },
        pathKeyisFullPath: true
      }
    );
  };

  const emptyContent = (
    <S.Flex column overflowHidden muted>
      <HtmlContent content={t('components.PromotionsModule.noPromotionsAvailable')}/>
    </S.Flex>
  );

  return (
    <Module
      title={t('components.PromotionsModule.activePromotions')}
      flex={props.flex}
      isLoading={moduleIsLoading}
      isEmpty={!promotions.length}
      emptyContent={emptyContent}
    >
      {promotions && promotions.map((promotion, i) => (
        <NameDatesRow
          key={`completed-campaign-${promotion.id}`}
          token={token}
          record={promotion}
          setStore={setStore}
          navigateToRecord={navigateToPromotion}
        />
      ))}
    </Module>
  );
}
