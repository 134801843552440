import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Styles
import S from '../../styled';

// Model Requests
import { getShareCodesTask, updateTasks } from '../../data/requests/task';
import { getCodes } from '../../data/requests/codes';

// Components
import Page from '../../components/Page';
import Module from '../../components/Module';
import ModuleHeader from '../../components/ModuleHeader';
import TaskInstructions from '../../components/TaskInstructions';

// Helpers
import { taskNameWithDueDateObject } from '../../helpers/taskNameDueDate';

// Constants
import { FLASH_TYPE_SUCCESS, FLASH_TYPE_ERROR } from '../../helpers/constants';

export default function ShareCodesTask(props) {
  const history = useHistory();

  const [shareCodesTask, setShareCodesTask] = useState({});
  const [isLoadingShareCodes, setIsLoadingShareCodes] = useState(true);
  const [showCodesReceivedButton, setShowCodesReceivedButton] = useState(true);
  const [codes, setCodes] = useState([])

  const { store, setStore } = props;
  const { taskId: shareCodesTaskIdQueryParam } = props.match.params;
  const { token, t } = store;
  const { setRenderFlashMessage } = setStore;

  const successMessage = useCallback(() => {
    setRenderFlashMessage(
      t('pages.ShareCodesTask.codesReceived'),
      FLASH_TYPE_SUCCESS
    );
  }, [setRenderFlashMessage, t]);

  const errorMessage = useCallback(() => {
    setRenderFlashMessage(
      t('pages.ShareCodesTask.errorReceivingCodes'),
      FLASH_TYPE_ERROR
    );
  }, [setRenderFlashMessage, t]);

  const completeShareCodesTask = useCallback(async () => {
    try {
      const response = await updateTasks(token, shareCodesTaskIdQueryParam);

      if (response?.['json']?.['completed_at']) {
        setShowCodesReceivedButton(false);
        successMessage();
      } else {
        errorMessage();
      }
    } catch (error) {
      errorMessage();
    }
  }, [errorMessage, shareCodesTaskIdQueryParam, successMessage, token])

  const getShareCodesTaskAndClaimCodes = useCallback(async () => {
    if (!token) return;

    setIsLoadingShareCodes(true);
    try {
      const shareCodesTask = await getShareCodesTask(token, shareCodesTaskIdQueryParam);
      setShareCodesTask(shareCodesTask);
      const { code_bank_id: codeBankId, quantity, id: contactTaskId, is_complete: isComplete } = shareCodesTask;
      const availableCodes = await getCodes(token, codeBankId, quantity, contactTaskId);

      setCodes(availableCodes);

      if (isComplete) {
        setShowCodesReceivedButton(false);
      }
    } catch (error) {
      console.error('getShareCodesTaskAndCodes: ', error);
    } finally {
      setIsLoadingShareCodes(false);
    }
  }, [token, shareCodesTaskIdQueryParam]);

  useEffect(() => {
    getShareCodesTaskAndClaimCodes();
  }, [getShareCodesTaskAndClaimCodes]);

  const moduleTitle = (
    <ModuleHeader moduleName={taskNameWithDueDateObject(shareCodesTask)} history={history} />
  );

  const codesReceivedButton = (
    <S.Flex column alignCenter flex={1} overflowHidden>
      <S.Flex justifyCenter alignCenter desktopStyles={{ width: '50%' }}>
        {showCodesReceivedButton && (
          <S.Button onClick={completeShareCodesTask}>{t('pages.ShareCodesTask.codesReceivedButton')}</S.Button>
        )}
      </S.Flex>
    </S.Flex>
  );

  const renderCodes = () => {
    return (
      <S.Flex column marginTop={16} marginBottom={16} lineHeight={1.5}>
        {codes ?
            <>
              {codes.map(code => <S.Text taskFont key={code.id} as="h3">{code.value}</S.Text>)}
            </>
          :
          <S.Text>{t('pages.ShareCodesTask.noMoreCodes')}</S.Text>
        }
      </S.Flex>
    )
  }

  return (
    <Page>
      <Module
        flex={1}
        titleBlock={moduleTitle}
        isLoading={isLoadingShareCodes}
      >
        <TaskInstructions instructionsHtml={shareCodesTask?.instructions_html} />
        {renderCodes()}
        {codes && codesReceivedButton}
      </Module>
    </Page>
  );
}
