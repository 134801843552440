import api from '../api';

const basePath = 'communication_preferences';

export const getCommunicationPreferences = (token) =>
  api({
    method: 'get',
    url: `${basePath}/${token}`,
  });

export const updateCommunicationPreferences = (token, params) =>
  api({
    method: 'patch',
    url: `${basePath}/${token}`,
    data: {
      receive_new_campaign_communications: params.receiveWelcomeCommunications,
      receive_update_campaign_communications: params.receiveUpdateCampaignCommunications,
      receive_new_campaign_signup_communications: params.receiveNewCampaignSignupCommunications,
      receive_tasks_due_communications: params.receiveTasksDueCommunications
    },
  });
