import React, { useState, useEffect, useCallback } from 'react';
import S from '../../styled';
import { FLASH_TYPE_SUCCESS, FLASH_TYPE_ERROR } from '../../helpers/constants';

// Components
import Module from '../Module';

// Requests
import {
  getDelegateRelationships,
  createDelegateRelationship,
  deleteDelegateRelationship
} from '../../data/requests/delegates';

// Helpers
import { EMAIL_VALIDATION_REGEX } from '../../helpers/constants';

export default function DelegateRelationshipModule(props) {
  const { setStore } = props;
  const { token, currentUser, t } = props.store;

  const [moduleData, setModuleData] = useState({});
  const [moduleIsLoading, setModuleIsLoading] = useState(false);
  const [renderForm, setRenderForm] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [delegateRelationships, setDelegateRelationships] = useState([]);

  const { setRenderFlashMessage, setRenderModal, setModalContentUnsafe } = setStore;

	const showForm = () => setRenderForm(true);
  const hideForm = () => setRenderForm(false);

  const successMessage = useCallback((message) => {
    setRenderFlashMessage(
      message,
      FLASH_TYPE_SUCCESS
    );
  }, [setRenderFlashMessage]);

  const errorMessage = useCallback((message) => {
    setRenderFlashMessage(
      message,
      FLASH_TYPE_ERROR
    );
  }, [setRenderFlashMessage]);

  const getDelegates = useCallback(async () => {
    if (!token) return;

    try {
      setModuleIsLoading(true);
      const data = await getDelegateRelationships(token);
      setDelegateRelationships(data);
    } catch (error) {
      console.log('getDelegateRelationships: ', error);
    } finally {
      setModuleIsLoading(false);
    }
  }, [token]);

  const removeDelegate = useCallback(async (delegateRelationshipId, email) => {
    if (!token || !delegateRelationshipId || !delegateRelationships.length) return;

    try {
      setModuleIsLoading(true);
      const deletedDelegateResponse = await deleteDelegateRelationship(
        token, delegateRelationshipId
      );

      const filteredDelegateRelationships = delegateRelationships.filter(
        delegate => delegate.id !== deletedDelegateResponse.id
      );

      successMessage(`${t('components.DelegateRelationShipModel.accessFor')} ${deletedDelegateResponse.email} ${t('components.DelegateRelationShipModel.hasBeenRemoved')}`);
      setDelegateRelationships(filteredDelegateRelationships);
    } catch (error) {
      console.log('deleteDelegateRelationship: ', error);
      errorMessage(`${t('components.DelegateRelationShipModel.errorOccurredRemoving')} ${email}`);
    } finally {
      setRenderModal(false);
      setModuleIsLoading(false);
    }
  }, [delegateRelationships, errorMessage, setRenderModal, successMessage, t, token]);

  const openRemoveDelegateModal = (delegateRelationshipId, email) => {
    setModalContentUnsafe(
      <S.Flex alignCenter textAlign='center' column>
        <S.Flex alignCenter textAlign='center' column>
          <S.Flex>
            {t('components.DelegateRelationShipModel.removeDelegate')} {email}?
          </S.Flex>
        </S.Flex>
        <S.Flex gap={20} margin={45}>
          <S.Button gap={8}
            disabled={moduleIsLoading}
            onClick={() => removeDelegate(
              delegateRelationshipId, email
            )}
          >
            {t('shared.confirm')}
          </S.Button>
          <S.Button secondary onClick={() => setRenderModal(false)}>
            {t('shared.cancel')}
          </S.Button>
        </S.Flex>
      </S.Flex>
    );

    setRenderModal(true);
  };

  useEffect(() => {
    getDelegates();
  }, [getDelegates]);

  const invalidEmail = (email) => {
    const relationshipExists = delegateRelationships.some(relationship => relationship.email === email)
    const isCurrentUserEmail = email === currentUser.email
    if (!validEmail) {
      errorMessage(t('components.DelegateRelationShipModel.emailInvalid'));
      return true;
    } else if (isCurrentUserEmail) {
      errorMessage(t('components.DelegateRelationShipModel.selfDelegateError'));
      return true;
    } else if (relationshipExists) {
      errorMessage(t('components.DelegateRelationShipModel.duplicatedEmail'));
      return true;
    }
  };

  const onEmailSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    if (invalidEmail(email)) return ;
    try {
      setModuleIsLoading(true);
      const data = await createDelegateRelationship(token, email);
      setModuleData(data);
      setDelegateRelationships(delegateRelationships.concat(data));
      successMessage(t('components.DelegateRelationShipModel.inviteSent'));
      hideForm();
    } catch (error) {
      console.error('createDelegateRelationship: ', error);
      errorMessage(t('components.DelegateRelationShipModel.delegateEmailAddError'));
    } finally {
      setModuleIsLoading(false);
    }
  };

  const onEmailChange = (e) => {
    setValidEmail(EMAIL_VALIDATION_REGEX.test(e.target.value));
  };

  const delegates = (
    delegateRelationships.map(({id, email}) =>
      <S.Flex justifyBetween marginBottom={10} key={`delegate-${id}`}>
        <S.Text wordBreakWord lightWeight>
          {email}
        </S.Text>
        <S.Link
          lightWeight
          underline
          fontSize={16}
          margin={0}
          disabled={moduleIsLoading}
          onClick={() => openRemoveDelegateModal(
            id,
            email
          )}
        >
          {t('shared.remove')}
        </S.Link>
      </S.Flex>
    )
  );

  const noDelegates = (
    <S.Flex justifyCenter>
      <S.Text>
        {t('components.DelegateRelationShipModel.noDelegatedAccess')}
      </S.Text>
    </S.Flex>
  );

  return (
    <Module title={t('components.DelegateRelationShipModel.delegateAccess')} isLoading={moduleIsLoading}>
      <S.Flex column gap={24} alignStart justifyStart>
        <S.Flex gap={24} flex={0}>
          <S.Text as="p" maxWidth lightWeight marginTop={3}>
            {t('components.DelegateRelationShipModel.designatedDelegatesInfo')}
          </S.Text>
        </S.Flex>
        <S.Flex gap={24} flex={0}>
          <S.Flex column marginBottom={24}>
            {delegates.length ?
              <>
                <S.Flex justifyStart marginBottom={10}>
                  <S.Text>{t('components.DelegateRelationShipModel.delegates')}</S.Text>
                </S.Flex>
                {delegates}
              </>
              :
              <>
                {noDelegates}
              </>
            }
          </S.Flex>
        </S.Flex>
        <S.Flex gap={24} marginBottom={24} flex={0}>
          {renderForm ?
            <S.Form column gap={26} onSubmit={onEmailSubmit}>
              <S.Input
                name="email"
                placeholder={t('shared.emailAddress')}
                type='email'
                onChange={onEmailChange}
              />
               <S.Flex gap={20} justifyEnd>
                <S.Button
                  smallSize
                  height={12}
                  width={100}
                  type="cancel"
                  secondary
                  disabled={moduleIsLoading}
                  onClick={hideForm}>
                  {t('shared.cancel')}
                </S.Button>
                <S.Button
                  smallSize
                  height={12}
                  width={100}
                  type="submit"
                  disabled={moduleIsLoading}>
                  {t('shared.save')}
                </S.Button>
               </S.Flex>
            </S.Form>
          :
            <S.Link href='#' onClick={showForm}>+ {t('components.DelegateRelationShipModel.addDelegate')}</S.Link>
          }
        </S.Flex>
      </S.Flex>
    </Module>
  );
};
