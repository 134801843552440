import React, { useState, useEffect, useCallback } from 'react';
import S from '../../styled';
import { useHistory } from 'react-router-dom';

//Routes
import { paths, routeNames } from '../../routing/routes';

// Model Requests
import { getCampaignsByToken } from '../../data/requests/activations';

// Components
import NameDatesRow from '../Reusable/NameDatesRow';
import Module from '../Module';

// Helpers
import navigateTo from '../../helpers/navigateTo';

export default function MyCampaignsModule(props) {
  const history = useHistory();

  const { store, setStore, completed, dashboardView } = props;
  const { token, t } = store;

  const [campaigns, setCampaigns] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const getCampaigns = useCallback(async () => {
    if (!token) return;

    try {
      setIsLoading(true);

      const data = await getCampaignsByToken(token, completed);
      if (!data || !data.length) setIsEmpty(true);
      setCampaigns(data);
    } catch (error) {
      console.error('getCampaignsByToken: ', error);
    } finally {
      if (isMounted) setIsLoading(false);
    }
  }, [token, isMounted, completed]);

  useEffect(() => {
    getCampaigns();
    return () => setIsMounted(false);
  }, [getCampaigns]);

  const navigateToCampaign = (campaign) => {
    navigateTo(
      history,
      paths.campaignSummary,
      token,
      {
        queryParams: {
          campaignId: campaign.id,
        },
        pathKeyisFullPath: true
      }
    );
  };

  const navigateToCampaigns = (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.campaigns, token)
  };

  const title = completed ? t('components.Campaigns.completedCampaigns') : t('components.Campaigns.activeCampaigns')

  const emptyContentText = () => {
    return completed ? t('components.Campaigns.noCompletedCampaignsToDisplay') : t('components.Campaigns.noActiveCampaignParticipation')
  };

  const emptyContent = (
    <S.Text
      maxWidth
      textAlignCenter
      muted
      paddingTop={40}
      paddingBottom={40}
    >
      {emptyContentText()}
    </S.Text>
  );

  const topRightContent = (
    <S.Flex row alignEnd justifyEnd flex={1}>
      <S.Link
        href={'#'}
        muted
        hoverable
        clickable
        fontSize={14}
        flex={0}
        onClick={navigateToCampaigns}
      >
       {t('components.Campaigns.viewCampaigns')}
      </S.Link>
    </S.Flex>
  );

  return (
    <Module
      isLoading={isLoading}
      title={title}
      isEmpty={isEmpty}
      emptyContent={emptyContent}
      topRightContent={dashboardView ? topRightContent : null}
    >
    {campaigns.map((campaign) => (
      <NameDatesRow
        key={`completed-campaign-${campaign.id}`}
        token={token}
        record={campaign}
        setStore={setStore}
        navigateToRecord={navigateToCampaign}
      />
    ))}
  </Module>
  );
};
